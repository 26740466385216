import React from "react";
import './profileData.css'
import {Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage,injectIntl} from "react-intl";
import * as moment from "moment";
import keycloak from "../../keycloak";
import {loginUser} from "../../Router";
import Cookies from "universal-cookie";
import axios from "axios";
import {Spin} from "antd";
import doneCorrect from "../images/correct.svg"

const cookies = new Cookies();
class ProfileData extends React.Component {
    state = {
        modal: false,
        tokenInvestor: "",
        Data: [],
        isLoading: true,
        numberShares:0,
        shares:"",
        errors: {},
        doneShares:false,
        disabled:false
    }
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))

    }
    getDataProfils = (token) => {
        axios.get(process.env.REACT_APP_API_BASE_URL + `/api/method/variiance_investor.investment.doctype.investors.api.get_investor_profile`, {
            headers: {
                Authorization: `token ${token}` //the token is a variable which holds the token
            }
        })
            .then((response) => {
                let Data = response.data.message.profile_data;
                this.setState({Data});
                this.setState({
                    isLoading: false,
                });
            });
    }
    componentDidMount() {
        let lang = "";
        if (cookies.get('locale') === 'en') {
            this.setState({lang: "en"});
            lang = "en";
            moment.locale('en-au');
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
            moment.locale('ar');

        }
        if (!keycloak.tokenParsed.investor_token) {
            loginUser().then(resp => this.setState({tokenInvestor: resp.message.Investor_token}, function () {
                this.getDataProfils(this.state.tokenInvestor)
            }))
        } else {
            this.setState({tokenInvestor: keycloak.tokenParsed.investor_token}, () => {
                this.getDataProfils(this.state.tokenInvestor)
            })
        }
    }
    handleChange = (event,name) =>{
        this.setState({[event.target.name] : event.target.value},()=>{
            let errors = {};
            let formIsValid = true;
            if (name === "shares") {
                if (!this.state.shares) {
                    formIsValid = false;
                    errors.shares =  <FormattedMessage id="Cannot be empty"/>
                } else if (this.state.shares < 1) {
                    formIsValid = false;
                    errors.shares = <FormattedMessage id="Cannot be less than 1 EGP"/>
                }  else {
                    errors.shares = ""
                    formIsValid = true;

                }
            }

            this.setState({errors: errors});
            return formIsValid;
        })
    }
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.shares < 1) {
            formIsValid = false;
            errors.shares = <FormattedMessage id="Cannot be less than 1 EGP"/>
        }
        if (!this.state.shares) {
            formIsValid = false;
            errors.shares =  <FormattedMessage id="Cannot be empty"/>
        }

        this.setState({errors: errors})
        return formIsValid;

    }
    handleSubmit = () => {
        if (this.handleValidation() === false) {
        }else{
            this.setState({disabled: true})
            const params = {
                user_id: keycloak.tokenParsed.email,
                no_of_shares: this.state.shares,
            };
            axios.post(process.env.REACT_APP_API_BASE_URL + "/api/resource/Shares Requests",params, {
                headers: {
                    Authorization: `token ${this.state.tokenInvestor}` //the token is a variable which holds the token
                }
            })
                .then((res) => {
                        if(res.status === 200){
                            this.setState({doneShares:true,disabled:false,shares:""})
                            setTimeout(() => {
                                this.toggleModal()
                            }, 3000)
                            setTimeout(() => {
                                this.setState({doneShares:false})
                            }, 3500)
                        }
                    }
                ).catch((error) => {
            })

        }
    }


    render() {
        let {Data} = this.state
        const {intl} = this.props;
        let rendered = this.state.isLoading ? (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : ( <React.Fragment>
            <hr/>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Email Address"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.email}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Mobile Number"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.phone_number}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="National ID"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.national_id}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Address"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.address}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Job Title"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.job_title}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Bank Account"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.bank_account}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Age"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.age} <FormattedMessage id="years"/></p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 headerProfile"><FormattedMessage id="Birth Of Date"/></p>
                    </div>
                    <div className="col-12 text-center">
                        <p className="user-name m-0 text-bold-600 profileData">{Data.birth_of_date}</p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <Button
                            color=""
                            className="btnBuy"
                            onClick={this.toggleModal}
                        >
                            <FormattedMessage id="Buy New Shares"/>
                        </Button>
                    </div>

                </div>

            </div>
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                contentClassName="modalViewContent"
                className="modal-dialog-centered"
            >
                {!this.state.doneShares?
             <>
                 <ModalHeader  className="m-auto modalViewContentHeader">
                     <p ><FormattedMessage
                         id="How Many Shares Do You Want To Add ?"
                         required/></p>

                 </ModalHeader>
                 <ModalBody>
                     <FormGroup className="has-icon-left position-relative px-3">
                         <Input className="pl-1 py-2" placeholder={intl.formatMessage({id: "Enter Desired Number Of Shares"})} type="number" name="shares"
                                onSelectCapture={(e) => this.handleChange(e, 'shares')}
                                onChange={(e) => this.handleChange(e, 'shares')}/>

                     </FormGroup>
                     <span className="error">{this.state.errors.shares}</span>

                 </ModalBody>

                 <ModalFooter className="text-center justify-content-center">


                     <Button className="InvestorButton"
                             style={{color:"white"}}
                             color=""
                             disabled={this.state.disabled}
                             onClick={this.handleSubmit}
                     ><FormattedMessage id="Submit Request"/></Button>

                 </ModalFooter>
             </>: <ModalBody className="text-center p-3">
                        <img src={doneCorrect} className="image_correct" width="80" height="80"/>
                        <p ><FormattedMessage
                            id="Your request has been sent successfully our team will contact you soon"
                            required/></p>

                    </ModalBody>}
            </Modal>
        </React.Fragment>)

        return <div>{rendered}</div>;
    }
}



export default injectIntl(ProfileData);
