import React from "react"
import {ContextLayout} from "../../../utility/context/Layout"
import Radio from "../radio/RadioVuexy"
import "../../../assets/scss/components/customizer.scss"
import {CustomInput} from "reactstrap";
import {BsSun, FaMoon} from "react-icons/all";
import themeConfig from "../../../configs/themeConfig";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Customizer extends React.Component {

    state = {
        activeNavbar: this.props.activeNavbar,
        navbarType: null,
        footerType: null,
        menuTheme: null,
        collapsedSidebar: null,
        dark:false
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.navbarType !== state.navbarType ||
            props.footerType !== state.footerType ||
            props.menuTheme !== state.menuTheme ||
            props.activeMode !== state.activeMode ||
            props.sidebarState !== state.collapsedSidebar
        ) {
            return {
                activeNavbar: props.activeNavbar,
                navbarType: props.navbarType,
                footerType: props.footerType,
                menuTheme: props.menuTheme,
                activeMode: props.activeMode,
                collapsedSidebar: props.sidebarState
            }
        }
        // Return null if the state hasn't changed
        return null
    }

    componentDidMount() {
        this.setState({
            navbarType: this.props.navbarType,
            footerType: this.props.footerType,
            menuTheme: this.props.menuTheme,
            activeMode: this.props.activeMode,
            collapseSidebar: this.props.sidebarState,
        })


        const mode = cookies.get('mode') || ''
        this.onChange(null, mode == 'dark')
    }

    onChange = (e, dark) => {
        if (dark) {
            this.props.changeMode("dark")
            cookies.set('mode','dark')
            this.setState({dark:true})

        } else {
            this.props.changeMode("light")
            cookies.set('mode','light')
            this.setState({dark:false})
        }
    }

    render() {
        const {
            activeMode,
        } = this.state
        return (
            <ContextLayout.Consumer>
                {context => {
                    return (
                        <>
                            <div>
                                {this.state.dark ?
                                    <BsSun size={20}  style={{cursor:"pointer"}} onClick={(e) => this.onChange(e, false)}/> :
                                    <FaMoon size={20} style={{cursor:"pointer"}} onClick={(e) => this.onChange(e, true)}/>
                                }
                            </div>
                            {/*<div className="mx-1">*/}
                            {/*    /!*<CustomInput id='plan-switch' type='switch' onChange={this.onChange}/>*!/*/}
                            {/*</div>*/}
                        </>
                    )
                }}
            </ContextLayout.Consumer>
        )
    }
}


export default Customizer
