import React from "react"
import {NavItem, NavLink, UncontrolledTooltip} from "reactstrap"
import * as Icon from "react-feather"
import variianceLight from '../../../views/images/LogoHomePage.svg'
import variianceDark from '../../../views/images/logo.png'

import {connect} from "react-redux"
import {loadSuggestions, updateStarred} from "../../../redux/actions/navbar/Index"
import {
    changeFooterType,
    changeMenuColor, changeMode,
    changeNavbarColor,
    changeNavbarType, hideScrollToTop, profileFalse
} from "../../../redux/actions/customizer";

class NavbarBookmarks extends React.PureComponent {
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.app.customizer.theme !== this.props.app.customizer.theme){
        }
    }

    render() {
        let {
            sidebarVisibility,

        } = this.props


        return (
            <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                        <NavLink
                            className="nav-menu-main menu-toggle hidden-xs is-active"
                            onClick={sidebarVisibility}>
                            <Icon.Menu className="ficon"/>
                        </NavLink>
                    </NavItem>
                </ul>
              <ul className="nav navbar-nav bookmark-icons">
          <NavItem className="nav-item d-none d-lg-block">
            <NavLink className="pb-0">
                {this.props.app.customizer.theme === "light"? <img src={variianceDark} width={100} height={28}/>:<img src={variianceLight} width={100} height={45}/>}

            </NavLink>
          </NavItem>
        </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        bookmarks: state.navbar,
        app: state.customizer,
        profileFalse

    }
}

export default connect(mapStateToProps, {loadSuggestions, updateStarred, changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    hideScrollToTop,
    changeMode})(
    NavbarBookmarks
)
