import React, {lazy, Suspense, useEffect, useState} from "react"
import {BrowserRouter, Route, Switch} from "react-router-dom"
import {history} from "./history"
import {connect} from "react-redux"
import Spinner from "./components/@vuexy/spinner/Loading-spinner"
import {ContextLayout} from "./utility/context/Layout"
import {useKeycloak} from '@react-keycloak/web';
import keycloak from './keycloak'
import Cookies from "universal-cookie";
import axios from "axios";
import {Spin} from "antd";
import './views/all.css'
import NOT from "./views/Components/notInvestor"


const cookies = new Cookies();



const PdfViewer = lazy(() =>
    import("./views/pages/PdfViewer")
)
const Notifications = lazy(() =>
    import("./views/pages/Notifications")
)
const SingleNotifications = lazy(() =>
    import("./views/pages/SingleNotifications")
)
const Articles = lazy(() =>
    import("./views/pages/Articles")
)
const SingleArticles = lazy(() =>
    import("./views/pages/SingleArticles")
)
const Profile = lazy(() =>
    import("./views/pages/Profile")
)
const Wallet = lazy(() =>
    import("./views/pages/wallet")
)
const Documents = lazy(() =>
    import("./views/pages/Documents")
)
const meeting = lazy(() =>
    import("./views/pages/meeting")
)
const singleResult = lazy(() =>
    import("./views/pages/singleResult")
)
const singleMeeting = lazy(() =>
    import("./views/pages/singleMeeting")
)
const Performance = lazy(() =>
    import("./views/pages/Performance")
)
const Summary = lazy(() =>
    import("./views/pages/Summary")
)


// Set Layout and Component Using App Route
const RouteConfig = ({
                         component: Component,
                         fullLayout,
                         permission,
                         user,
                         ...rest
                     }) => (
    <Route
        {...rest}
        render={props => {
            return (
                <ContextLayout.Consumer>
                    {context => {
                        let LayoutTag =
                            fullLayout === true
                                ? context.fullLayout
                                : context.state.activeLayout === "horizontal"
                                    ? context.horizontalLayout
                                    : context.VerticalLayout
                        return (
                            <LayoutTag {...props} permission={props.user}>
                                <Suspense fallback={<Spinner/>}>
                                    <Component {...props} />
                                </Suspense>
                            </LayoutTag>
                        )
                    }}
                </ContextLayout.Consumer>
            )
        }}
    />
)
const mapStateToProps = state => {
    return {
        user: state.auth.login.userRole
    }
}

export function loginUser () {

    const params = {
        "usr": keycloak.tokenParsed.email,
    };

    return axios
        .post(process.env.REACT_APP_API_BASE_URL+"/api/method/variiance_investor.investment.doctype.api.login", params)
        .then((res) => res.data);
}


const guardAuth = (Component) => () => {
    return keycloak && !keycloak.authenticated ? (
        keycloak.login()
    ) : (
        <Component/>
    );
};


const AppRoute = connect(mapStateToProps)(RouteConfig)


const AppRouter = () => {
    const {initialized} = useKeycloak();
    const [loading, setLoading] = useState(true);
    const [found, setFound] = useState(true);
    const getInvestor = async () => {
        const params = {
            sso_sub: keycloak.tokenParsed.sub,
            email: keycloak.tokenParsed.email,
        };
        await axios.post(process.env.REACT_APP_API_BASE_URL + "/api/method/variiance_investor.investment.doctype.investors.api.check_investor", params)
            .then((res) => {
                    setFound(res.data.message.status)
                    setLoading(false)
                }
            ).catch((error) => {
                setFound(false)
                setLoading(false)

            })
    }
    useEffect(() => {
        if(keycloak.authenticated){
            setLoading(true)
            getInvestor()
        }else{
            setLoading(false)
        }
    }, [initialized]);
    if (!initialized) {
        return true
    } else {
        if (keycloak.authenticated) {
            if (cookies.get('locale_changed') == "true") {
                cookies.set('language sso', cookies.get('locale'))
                cookies.set('locale_changed', "false")
            } else {
                cookies.set('language sso', keycloak.tokenParsed.language != 'undefined' ? cookies.get('language sso') : 'ar')
            }
            let languageSettings = [{
                "locale": "ar",
                'direction': 'rtl',
                'lang': 'eg',
                'country': 'العربية'
            }, {"locale": "en", 'direction': 'ltr', 'lang': 'us', 'country': 'English'}];

            let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
            cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
            cookies.set('country', languageSettings.find(x => x.locale === locale).country)
            cookies.set('direction', languageSettings.find(x => x.locale === locale).direction)
            // cookies.set('locale',cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar')

        } else {

        }
    }
    return (<>

            {loading ?
                <div className="container cont">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    {!found ? <NOT/> :
                        <BrowserRouter history={history}>
                            <Switch>
                                <AppRoute
                                    exact
                                    path="/"
                                    component={guardAuth(Summary)}
                                />
                                <AppRoute
                                    path="/Performance"
                                    component={guardAuth(Performance)}
                                />
                                <AppRoute
                                    exact
                                    path="/Documents"
                                    component={guardAuth(Documents)}
                                />
                                <AppRoute
                                    path="/profile"
                                    component={guardAuth(Profile)}
                                />
                                <AppRoute
                                    path="/Notifications"
                                    component={guardAuth(Notifications)}
                                />

                                <AppRoute
                                    path="/SingleNotifications/:id"
                                    component={guardAuth(SingleNotifications)}
                                />
                                <AppRoute
                                    path="/Articles"
                                    component={guardAuth(Articles)}
                                />
                                <AppRoute
                                    path="/SingleArticles/:id"
                                    component={guardAuth(SingleArticles)}
                                />
                                <AppRoute
                                    path="/Wallet"
                                    component={guardAuth(Wallet)}
                                />

                                <AppRoute
                                    path="/meetings"
                                    component={guardAuth(meeting)}
                                />
                                <AppRoute
                                    path="/SingleMeeting/:id"
                                    component={guardAuth(singleMeeting)}
                                />
                                <AppRoute
                                    path="/resultVoting/:id"
                                    component={guardAuth(singleResult)}
                                />
                                <AppRoute path="/pdfViewer"
                                          component={guardAuth(PdfViewer)}
                                          />
                            </Switch>
                        </BrowserRouter>}
                </>}
        </>
    );
};
export default AppRouter
