import React, {Component} from "react";
import img from "../../../src/assets/img/logo.png";
import {Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
// import '../pages/all.css'

class NOT extends Component {
    state = {};

    render() {

        return (
            <div className="container cont">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <img className="picnot" src={img} alt="not found"/>
                        <h1 className="not_found mt-4">
                            <FormattedMessage id="You Are Not allowed to access Investor dashboard"
                                              required/>
                        </h1>
                        <a href={process.env.REACT_APP_VARIIANCE}>
                            <Button className="backbutton" color="warning">
                                <FormattedMessage id="Back" required/>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default NOT;
