import React from "react"
import * as Icons from "react-icons/all"

const navigationConfig = [
    {
        id: "Summary",
        title: "Summary",
        type: "item",
        icon: <Icons.CgMenuGridO size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },
    {
        id: "Performance",
        title: "Performance",
        type: "item",
        icon: <Icons.AiOutlineLineChart size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Performance"
    },
    {
        id: "Documents",
        title: "Documents",
        type: "item",
        icon: <Icons.IoDocumentSharp size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Documents"
    },
    {
        id: "Articles",
        title: "Articles",
        type: "item",
        icon: <Icons.IoIosPaper size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Articles"
    },
    {
        id: "meetings",
        title: "Meetings",
        type: "item",
        icon: <Icons.AiOutlineVideoCamera size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/meetings"
    },
    {
        id: "Wallet",
        title: "Wallet",
        type: "item",
        icon: <Icons.BsWallet2 size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Wallet"
    },

]

export default navigationConfig
