import React, {Component} from "react"
import {Circle, Disc, X} from "react-feather"
import classnames from "classnames"
import theimage from './logo (2).png'
import './side.css'
import Lang from "../../navbar/lang";
import {DropdownToggle, UncontrolledDropdown} from "reactstrap";
import keycloak from "../../../../keycloak";
import avatar1 from "../../../../views/images/avat.png";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {profileFalse} from "../../../../redux/actions/customizer";
import {CgLogOut, RiLogoutBoxRLine} from "react-icons/all";
import {FormattedMessage} from "react-intl";

class SidebarHeader extends Component {
    changeProfile = () =>{
        this.props.profileFalse(false)
        this.props.props(this.props.currentUser.customizer.customizer.profileFalse)
    }

    render() {
        let {
            toggleSidebarMenu, activeTheme, collapsed, toggle, sidebarVisibility, menuShadow
        } = this.props
        return (<div className="navbar-header">
            <ul className="nav navbar-nav">
                <li className="nav-item ">
                    <a className="navbar-brand">
                        {collapsed === true ? null :
                            <>
                                <div data-tour="user" className="text-center">
                                    <img
                                        src={keycloak.tokenParsed.image ? keycloak.tokenParsed.image : avatar1}
                                        className="round"
                                        height="100"
                                        width="100"
                                        alt="avatar"
                                    />
                                    <Link to="/profile">
                                    <p className="user-name text-bold-600 userNameTXT" onClick={this.changeProfile}>

                { keycloak.tokenParsed.name }
              </p>
                                    </Link>

                                    {keycloak && keycloak.authenticated ? <p className="logoutTXT my-md-2" onClick={() => keycloak.logout()}> <FormattedMessage id="Logout"/>  <RiLogoutBoxRLine size={20}/> </p>:null}
                                </div>
                            </>
                        }
                    </a>
                </li>
            </ul>
            <div
                className={classnames("shadow-bottom", {
                    "d-none": menuShadow === false
                })}
            />
        </div>)
    }
}
const mapStateToProps = state => {
    return {
        currentUser:state,

    }
}
export default connect(mapStateToProps,{
    profileFalse
})(SidebarHeader)
